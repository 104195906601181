<template>
  <div class="page flex-col">
    <div class="mainbox">
    <div class="section_1 flex-col">
      <div class="header justify-between">
        <div class="logo">
          <img src="./assets/img/Frame(1).png" alt="">
          <span>ANY CONSTRUCTION</span>
        </div>
       
          <div class="nav">
            <span class="active" @click="goPage('home')">หน้าแรก</span>
            <span class="text_3" @click="goPage('about')">เกี่ยวกับเรา</span>
            <span class="text_4" @click="goPage('services')">บริการของเรา</span>
            <span class="text_5" @click="goPage('contact')">ติดต่อเรา</span>
          </div>
          <div class="section_1_text">
            <div class="big">
              นวัตกรรมทางเทคโนโลยีขับเคลื่อนอนาคต

            </div>
            <div class="small">
              นวัตกรรมเป็นพลังขับเคลื่อนหลักสำหรับความก้าวหน้า บริษัทของเรามุ่งมั่นในกิจกรรมทางวิทยาศาสตร์และเทคโนโลยีระดับมืออาชีพมาโดยตลอด โดยมอบโซลูชันทางเทคโนโลยีที่ทันสมัยที่สุดแก่ลูกค้าผ่านการวิจัยและนวัตกรรมอย่างต่อเนื่อง เรามีทีมงานมืออาชีพที่ประกอบด้วยผู้เชี่ยวชาญในอุตสาหกรรม ยืนอยู่แถวหน้าด้านการพัฒนาเทคโนโลยีอยู่เสมอ และบรรลุอนาคตด้วยการพัฒนาที่ขับเคลื่อนด้วยนวัตกรรม เราเชื่อว่าเทคโนโลยีสามารถเปลี่ยนชีวิตได้และนวัตกรรมสามารถนำไปสู่อนาคตได้ เรายินดีที่จะทำงานร่วมกับลูกค้าของเราเพื่อสร้างความสำเร็จที่ยอดเยี่ยมร่วมกัน
            </div>
          </div>
   
      </div>
    </div>
    <div class="section_2 flex-col">
      <div class="linebox"></div>
      <div class="maintitle">
        เชี่ยวชาญวิทยาศาสตร์ที่แม่นยำและนำไปสู่ความสำเร็จที่โดดเด่น
      </div>
      <div class="mintitle">
        การวิจัยทางวิทยาศาสตร์และการประยุกต์ใช้เทคโนโลยีที่แม่นยำเป็นกุญแจสู่ความสำเร็จ ทีมงานของเราประกอบด้วยนักวิทยาศาสตร์และวิศวกรชั้นนำของอุตสาหกรรม อาศัยประสบการณ์อันยาวนานและวิธีการทางเทคนิคขั้นสูงเพื่อให้แน่ใจว่าทุกโครงการได้มาตรฐานสูงสุด ตั้งแต่ขั้นตอนการวิจัยเบื้องต้นจนถึงการใช้งานขั้นสุดท้าย เราใส่ใจทุกรายละเอียด มุ่งมั่นที่จะบรรลุผลลัพธ์ที่ยอดเยี่ยมในด้านต่างๆ และมอบโซลูชั่นที่น่าเชื่อถือที่สุดแก่ลูกค้า
      </div>
      <div class="stepbox justify-between">
        <div class="itemstep">
          <img src="./assets/img/Group 1171274798.png" alt="">
          <div class="stepmax">
            นวัตกรรมทางเทคโนโลยีนำไปสู่อนาคต
          </div>
          <div class="stepmin">
            มุ่งมั่นที่จะสร้างสรรค์นวัตกรรมทางเทคโนโลยี เราพัฒนาเทคโนโลยีที่ล้ำสมัยอย่างต่อเนื่องเพื่อนำประสบการณ์ผลิตภัณฑ์และโอกาสทางธุรกิจที่ไม่เคยมีมาก่อนมาสู่ลูกค้าของเรา

          </div>
        </div>
        <div class="itemstep">
          <img src="./assets/img/Group 1171274797.png" alt="">
          <div class="stepmax">
            ทีมงานมืออาชีพ โซลูชั่นที่แม่นยำ
          </div>
          <div class="stepmin">
            ด้วยทีมงานมืออาชีพที่ประกอบด้วยผู้เชี่ยวชาญในอุตสาหกรรม เราสามารถมอบโซลูชั่นที่แม่นยำแก่ลูกค้า ปรับแต่งปัญหาพิเศษแบบรอบด้าน และช่วยลูกค้าจัดการกับความท้าทายต่างๆ
          </div>
        </div>
        <div class="itemstep">
          <img src="./assets/img/Group 1171274797(1).png" alt="">
          <div class="stepmax">
            พัฒนาและส่งเสริมความก้าวหน้าของอุตสาหกรรมต่อไป
          </div>
          <div class="stepmin">
            ติดตามแนวโน้มการพัฒนาอุตสาหกรรม สร้างสรรค์นวัตกรรมและความก้าวหน้าอย่างต่อเนื่อง มอบเทคโนโลยีและโซลูชั่นที่ล้ำสมัยให้กับลูกค้า และช่วยให้ลูกค้าได้รับความได้เปรียบทางการแข่งขัน
          </div>
        </div>
      </div>
    </div>

    <div class="section_3 justify-between">
      <div class="mostleft">
        <div class="mostline"></div>
        <div class="mosttitle">
          สำรวจชายแดนและสร้างอนาคต
        </div>
        <div class="mostmin">
          ในระดับแนวหน้าของวิทยาศาสตร์และเทคโนโลยี เรายังคงสำรวจสิ่งที่ไม่รู้จักและเปิดโอกาสใหม่ ๆ ต่อไป ภารกิจของเราคือการส่งเสริมการพัฒนาและการเปลี่ยนแปลงของอุตสาหกรรมผ่านการวิจัยอย่างต่อเนื่องและนวัตกรรมทางเทคโนโลยี เราเชื่อว่าด้วยการสำรวจเทคโนโลยีที่ทันสมัยอย่างต่อเนื่อง เราสามารถมอบโซลูชั่นที่มองไปข้างหน้ามากที่สุดแก่ลูกค้า เพื่อช่วยให้พวกเขาโดดเด่นในการแข่งขันในตลาดที่รุนแรงและสร้างอนาคตที่สดใสยิ่งขึ้น
        </div>
        <div class="mostmal">
          คุณภาพดีเยี่ยมเชื่อถือได้
        </div>
        <div class="mostmin">
          ผลิตภัณฑ์และบริการของเรายึดมั่นในคุณภาพสูงเสมอโดยมีเป้าหมายหลักในการตอบสนองความต้องการของลูกค้า และได้รับการยอมรับจากตลาดอย่างกว้างขวางและความไว้วางใจจากลูกค้า
        </div>
      </div>
      <div class="mostright">
        <img src="./assets/img/Rectangle 65.png" alt="">
      </div>
    </div>

    <div class="section_4 justify-between">
      <div class="mostright">
        <img src="./assets/img/Rectangle 66.png" alt="">
      </div>

      <div class="mostleft">
       
        <div class="mostmal">
          เทคโนโลยี Zhihui บรรลุความเป็นไปได้ที่ไม่มีที่สิ้นสุด
        </div>
        <div class="mostmin">
          บริษัทของเรารวบรวมผู้เชี่ยวชาญชั้นนำจากสาขาต่างๆ และด้วยความรู้ทางวิชาชีพและประสบการณ์อันยาวนาน เราจึงสามารถมอบโซลูชันทางเทคนิคที่ปรับแต่งให้เหมาะกับลูกค้าได้ เรามุ่งมั่นที่จะผสมผสานภูมิปัญญาของวิทยาศาสตร์และเทคโนโลยีเข้ากับความต้องการของลูกค้าเพื่อสร้างผลลัพธ์ที่เป็นนวัตกรรมพร้อมมูลค่าการใช้งานที่หลากหลาย และช่วยให้ลูกค้าประสบความสำเร็จและประสบความสำเร็จมากยิ่งขึ้น
        </div>
        <div class="mostmal">
          เทคโนโลยีที่เป็นนวัตกรรมทำให้โลกแตกต่างเพราะเราและนำเทคโนโลยีเข้ามาใกล้เรามากขึ้น
        </div>
        <div class="mostmin">
          * ทีมงานของเรารวบรวมผู้มีความสามารถทางวิทยาศาสตร์และเทคโนโลยีชั้นนำในอุตสาหกรรม พวกเขายังคงส่งเสริมความก้าวหน้าทางเทคโนโลยีและนวัตกรรมด้วยความรู้ทางวิชาชีพที่ลึกซึ้ง เราเชื่อว่าความเป็นมืออาชีพเท่านั้นที่สามารถสร้างผลิตภัณฑ์ที่มีคุณค่าอย่างแท้จริง นวัตกรรมเท่านั้นที่สามารถทำให้โลกแตกต่างได้เพราะเรา เป้าหมายของเราคือการใช้เทคโนโลยีเพื่อเปลี่ยนแปลงโลกและนำไปสู่อนาคตด้วยนวัตกรรม
        </div>
        <div class="mostmin">
          * เราจะยังคงส่งเสริมความก้าวหน้าและการพัฒนาวิทยาศาสตร์และเทคโนโลยีโดยอาศัยเทคโนโลยีระดับมืออาชีพและขับเคลื่อนด้วยความคิดสร้างสรรค์ เราเชื่อมั่นว่าตราบใดที่เรายังคงสร้างสรรค์สิ่งใหม่ๆ ต่อไป อนาคตก็เป็นของเรา!
        </div>
      </div>
     
    </div>
    <div class="section_5 ">
      <div class="bg"></div>
      <div class="shouldbox justify-between">

        <div class="shouldleft">
          <div class="linebox">
          </div>
          <div class="shouldmax">
            ทำไมถึงเลือกพวกเรา?
          </div>
          <div class="shouldmin">
            บริษัทของเรามุ่งมั่นในกิจกรรมทางวิทยาศาสตร์และทางเทคนิคระดับมืออาชีพมาโดยตลอด โดยอาศัยข้อดีหลายประการ เช่น นวัตกรรมทางเทคโนโลยี ทีมงานมืออาชีพ ความเป็นเลิศด้านคุณภาพ นวัตกรรมที่ต่อเนื่อง วิสัยทัศน์ระดับโลก การบริการในท้องถิ่น ความร่วมมือ การพัฒนาสีเขียว และการฝึกอบรมและการสนับสนุนเพื่อให้ลูกค้าได้รับ โซลูชั่นทางเทคนิคขั้นสูงและบริการที่มีคุณภาพ เราเชื่อว่าด้วยความร่วมมืออย่างใกล้ชิดกับลูกค้าของเรา เราสามารถร่วมกันบรรลุเป้าหมายของการพัฒนาเทคโนโลยีและสร้างอนาคตที่ดีกว่าได้
          </div>
          <div class="shoulditem">
            <img src="./assets/img/Group 1171274797(2).png" alt="">
            ความสามารถด้านนวัตกรรมที่โดดเด่น: ผลิตภัณฑ์และเทคโนโลยีของเราได้รับการยอมรับอย่างสูงและมีการแข่งขันในตลาด และสามารถตอบสนองความต้องการที่หลากหลายของลูกค้าและนำมาซึ่งมูลค่าที่มากขึ้น
          </div>
          <div class="shoulditem">
            <img src="./assets/img/Group 1171274797(2).png" alt="">
            คุณภาพการบริการที่เป็นเลิศ: เรามุ่งเน้นประสบการณ์ของลูกค้าและให้การสนับสนุนบริการคุณภาพสูงทุกด้าน
          </div>
          <div class="shoulditem">
            <img src="./assets/img/Group 1171274797(2).png" alt="">
            จุดแข็งระดับมืออาชีพที่แข็งแกร่ง: เรามีเทคโนโลยีและทีมงานมืออาชีพชั้นนำของอุตสาหกรรมเพื่อให้แน่ใจว่าเราจะมอบโซลูชั่นคุณภาพสูงสุดให้กับคุณ
          </div>
        </div>
        <div class="shouldright">
          <div class="shouldcard">
            <img src="./assets/img/Group 1171274798(1).png" alt="">
            <div class="shouldcardmax">
              สำรวจและสร้างสรรค์สิ่งใหม่ๆ อย่างต่อเนื่อง
            </div>
            <div class="shouldcardmin">
              เราลงทุนทรัพยากรจำนวนมากในการวิจัยและพัฒนาและนวัตกรรมเพื่อทำลายปัญหาคอขวดทางเทคโนโลยีอย่างต่อเนื่องและส่งเสริมการพัฒนาของอุตสาหกรรม ผลิตภัณฑ์และเทคโนโลยีของเราได้รับการยอมรับอย่างสูงและแข่งขันในตลาดและสามารถตอบสนองความต้องการที่หลากหลายของลูกค้า
            </div>
          </div>
          <div class="shouldcard">
            <img src="./assets/img/Group 1171274799(1).png" alt="">
            <div class="shouldcardmax">
              กรณีความร่วมมือที่หลากหลาย
            </div>
            <div class="shouldcardmin">
              เราได้สร้างความสัมพันธ์ความร่วมมือระยะยาวและมั่นคงกับองค์กรที่มีชื่อเสียงหลายแห่งและสั่งสมความสำเร็จมากมาย
            </div>
          </div>
          <div class="shouldcard">
            <img src="./assets/img/Group 1171274800(1).png" alt="">
            <div class="shouldcardmax">
              วัฒนธรรมองค์กรอันเป็นเอกลักษณ์
            </div>
            <div class="shouldcardmin">
              เรามีวัฒนธรรมองค์กรเชิงบวก ความสามัคคี และการทำงานร่วมกัน เพื่อสร้างบรรยากาศการทำงานที่ดีสำหรับพนักงานของเรา
            </div>
          </div>
          <div class="shouldcard">
            <img src="./assets/img/Group 1171274801.png" alt="">
            <div class="shouldcardmax">
              วิธีการทางเทคนิคมีการปรับปรุงอย่างต่อเนื่อง
            </div>
            <div class="shouldcardmin">
              วิธีการทางวิทยาศาสตร์และเทคโนโลยีได้รับการปรับปรุงอย่างต่อเนื่อง ควบคู่ไปกับการวิจัยทางวิทยาศาสตร์ที่ซับซ้อนและการวิเคราะห์ทางเทคนิคเชิงลึกของเรา ไม่ว่าจะเป็นในด้านปัญญาประดิษฐ์ การวิเคราะห์ข้อมูล หรือระบบอัตโนมัติ เรามุ่งมั่นที่จะใช้เทคโนโลยีที่ทันสมัยกับธุรกิจจริงและ ส่งเสริมการพัฒนาสติปัญญาเพื่อช่วยให้ลูกค้าก้าวนำหน้าคลื่นเทคโนโลยีในอนาคต
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          ANY CONSTRUCTION
        </div>
        <div class="bottomtitlem">
          ติดต่อเราเพื่อความร่วมมือแบบ win-win
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            customer@anyconstructioncompany.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            639160090
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            391 ซอยพหลโยธิน 69/1 แขวงอนุสาวรีย์ เขตบางเขน กรุงเทพมหานคร 10220
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />