<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="header justify-between">
        <div class="logo">
          <img src="./assets/img/Frame(3).png" alt="">
          <span>ANY CONSTRUCTION</span>
        </div>
       
          <div class="nav">
            <span class="" @click="goPage('home')">หน้าแรก</span>
            <span class="" @click="goPage('about')">เกี่ยวกับเรา</span>
            <span class="active" @click="goPage('services')">บริการของเรา</span>
            <span class="text_5" @click="goPage('contact')">ติดต่อเรา</span>
          </div>
          <div class="section_1_text">
            <div class="big">
              บริการของเรา
            </div>
          </div>
   
      </div>
    </div>
    <div class="section_2">
      <div class="section_2left">
        <img src="./assets/img/Rectangle 66.png" alt="">
      </div>
      <div class="section_2right">
        <div class="section_2right_hea">
          <img src="./assets/img/Group 1171274834.png" alt="">
          การสนับสนุนการวิจัยทางวิทยาศาสตร์คุณภาพสูง
        </div>
        <div class="section_2right_mi">
          เรามุ่งมั่นที่จะให้บริการสนับสนุนการวิจัยทางวิทยาศาสตร์คุณภาพสูงและมีประสิทธิภาพแก่ลูกค้า ไม่ว่าจะเป็นการวิจัยขั้นพื้นฐานหรือการพัฒนาแอปพลิเคชัน ทีมงานของเรามีประสบการณ์มากมายและสั่งสมความรู้ทางเทคนิคอย่างลึกซึ้ง และสามารถนำเสนอโซลูชั่นที่ครอบคลุมได้
        </div>

        <div class="section_2right_hea">
          <img src="./assets/img/Group 1171274834(1).png" alt="">
          นวัตกรรมสินค้าและบริการ
        </div>
        <div class="section_2right_mi">
          ด้วยความสามารถด้านการวิจัยและพัฒนาที่ยอดเยี่ยม เรายังคงเปิดตัวผลิตภัณฑ์และบริการที่เป็นนวัตกรรมพร้อมข้อได้เปรียบที่ไม่เหมือนใคร ผลิตภัณฑ์เหล่านี้ไม่เพียงแต่ตอบสนองความต้องการในปัจจุบันของลูกค้าเท่านั้น แต่ยังคาดการณ์และแก้ไขปัญหาที่อาจเกิดขึ้นในอนาคตอีกด้วย
        </div>

        <div class="section_2right_hea">
          <img src="./assets/img/Group 1171274834(2).png" alt="">
          โซลูชันที่กำหนดเอง
        </div>
        <div class="section_2right_mi" style="border: none;">
          เรานำเสนอโซลูชั่นที่ปรับแต่งตามความต้องการเฉพาะของลูกค้าที่แตกต่างกัน ตั้งแต่การวางแผนโครงการไปจนถึงการดำเนินการจนถึงหลังการบำรุงรักษา เรามีส่วนร่วมในกระบวนการทั้งหมดเพื่อให้แน่ใจว่าทุกขั้นตอนสามารถตอบสนองความคาดหวังของลูกค้าได้
        </div>
      </div>
    </div>
    <div class="section_3">
      <div class="section_3left">
        <img src="./assets/img/Group 1171274846.png" alt="">
      </div>
      <div class="section_3right">
        <div class="linebox"></div>
        <div class="righttt">
          อะไรทำให้คุณเลือกบริการของเรา?

        </div>
        <div class="rightt2">
          การสนับสนุนทางเทคนิคที่แข็งแกร่ง
        </div>
        <div class="rightt3">
          
ทีมสนับสนุนด้านเทคนิคของเราประกอบด้วยผู้เชี่ยวชาญที่มีประสบการณ์ซึ่งไม่เพียงแต่มีความรู้ทางวิชาชีพที่มั่นคง แต่ยังมีประสบการณ์ในทางปฏิบัติมากมายอีกด้วย ไม่ว่าลูกค้าของเราจะเจอปัญหาอะไร เราก็สามารถตอบสนองได้อย่างรวดเร็วและมอบแนวทางแก้ไขที่มีประสิทธิภาพ
        </div>
      </div>
    </div>
    <div class="section_4">
      <div class="linebox"></div>
      <div class="buildtitle">ปรับปรุงและสร้างสรรค์วิทยาศาสตร์และเทคโนโลยีอย่างต่อเนื่อง</div>

      <div class="buildtitlemm">เรายึดมั่นในลูกค้าเป็นศูนย์กลางและแสวงหาความเป็นเลิศอย่างต่อเนื่อง ด้วยนวัตกรรมและการปรับปรุงทางเทคโนโลยีอย่างต่อเนื่อง เรามุ่งมั่นที่จะเกินความคาดหวังของลูกค้าในทุกโครงการ ช่วยให้พวกเขาประสบความสำเร็จในโลกที่เปลี่ยนแปลงอย่างรวดเร็วและท้าทาย</div>

      <div class="buildbox">
        <div class="buildboxitem">
          <img src="./assets/img/Frame.png" alt="">
          <div class="buildboxitemtt">
            บริการองค์กรที่เป็นเลิศ
          </div>
          <div class="buildboxitemmm">
            การบริการระดับองค์กรที่มีคุณภาพเป็นกุญแจสำคัญในการปรับปรุงความพึงพอใจและความภักดีของลูกค้า เรามุ่งเน้นที่การสร้างระบบการบริการลูกค้าที่สมบูรณ์ และปรับปรุงประสิทธิภาพการบริการผ่านทีมงานบริการลูกค้ามืออาชีพและวิธีการทางเทคนิค ซึ่งจะช่วยยกระดับประสบการณ์โดยรวมของลูกค้า
          </div>
        </div>
        <div class="buildboxitem">
          <img src="./assets/img/Frame(1).png" alt="">
          <div class="buildboxitemtt">
            ยึดมั่นในนวัตกรรมทางเทคโนโลยี
          </div>
          <div class="buildboxitemmm">
            เราลงทุนทรัพยากรอย่างต่อเนื่องในการวิจัยและพัฒนาเพื่อรักษาความเป็นผู้นำทางเทคโนโลยีของเรา ด้วยการได้รับความรู้และคำติชมจากลูกค้า เราจึงสามารถปรับปรุงผลิตภัณฑ์และบริการของเราได้อย่างต่อเนื่องเพื่อตอบสนองความต้องการของตลาดได้ดียิ่งขึ้น
          </div>
        </div>
        <div class="buildboxitem">
          <img src="./assets/img/Frame(2).png" alt="">
          <div class="buildboxitemtt">
            นำเสนอโซลูชั่นเฉพาะบุคคล
          </div>
          <div class="buildboxitemmm">
            เราจัดทำแผนบริการที่ออกแบบตามความต้องการและเฉพาะบุคคล โดยออกแบบโซลูชันที่ตรงกับเป้าหมายทางธุรกิจของลูกค้าตามความต้องการและความชอบเฉพาะของพวกเขา สิ่งนี้ไม่เพียงแต่รับประกันความพึงพอใจของลูกค้า แต่ยังช่วยเพิ่มความเข้าใจของเราในด้านใหม่ๆ ที่เฉพาะเจาะจงอีกด้วย
          </div>
        </div>
      </div>
    </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          ANY CONSTRUCTION
        </div>
        <div class="bottomtitlem">
          ติดต่อเราเพื่อความร่วมมือแบบ win-win
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            customer@anyconstructioncompany.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            639160090
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            Corumbá 562-516, Valle del Tepeyac, Gustavo A. Madero, 07740 Ciudad de México,CDMX,México
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />